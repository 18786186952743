<template>
  <v-card v-if="comment" outlined class="mb-2" color="secondary" dark>
    <div>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>{{
            comment.author ? comment.author.name : "Usuario eliminado"
          }}</v-list-item-title>
          <v-list-item-subtitle>{{
            fullDate(comment.date)
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </div>
    <v-card-text style="white-space: pre-wrap">
      <span>{{ comment.content }}</span>

      <div v-if="otherFiles.length" class="mt-2">
        <v-row dense>
          <v-col
            v-for="file in otherFiles"
            :key="file"
            cols="12"
            :md="showFullImage ? 12 : 6"
            :lg="showFullImage ? 12 : 4"
          >
            <v-card outlined light>
              <v-card-text>
                <v-btn
                  icon
                  small
                  color="primary"
                  class="mr-1"
                  @click="viewImage(file)"
                >
                  <v-icon>mdi-download</v-icon>
                </v-btn>
                <span>
                  <a :href="file" target="_blank" class="text--secondary">{{
                    getFilename(file)
                  }}</a>
                </span>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>

      <div v-if="imageFiles.length" class="mt-2">
        <v-row dense>
          <v-col cols="12" :md="showFullImage ? 12 : 6">
            <v-carousel v-model="carousel" height="200">
              <v-carousel-item v-for="(file, i) in imageFiles" :key="i">
                <v-img
                  :src="file"
                  @click="viewImage(file)"
                  style="cursor: pointer"
                  contain
                ></v-img>
              </v-carousel-item>
            </v-carousel>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { getFullDateWithHour } from "@/helpers/date.helper";

export default {
  name: "CommentCard",

  props: {
    comment: { type: Object, defualt: null },
    showFullImage: { type: Boolean, default: false },
  },

  data() {
    return {
      carousel: 0,
    };
  },

  computed: {
    imageFiles() {
      const imagesTypes = ["png", "jpg", "jpeg"];
      return this.comment.files.filter((file) => {
        const filenameArray = file.split(".");
        const filetype = filenameArray[filenameArray.length - 1];

        return imagesTypes.some((type) => type == filetype);
      });
    },

    otherFiles() {
      return this.comment.files.filter(
        (e) => !this.imageFiles.some((image) => image == e)
      );
    },
  },

  methods: {
    fullDate(isoDate) {
      return getFullDateWithHour(isoDate);
    },

    getFilename(url) {
      const stringArray = url.split("/");
      return stringArray[stringArray.length - 1];
    },

    viewImage(imageUrl) {
      const a = document.createElement("a");
      a.href = imageUrl;
      a.target = "_blank";

      a.click();
      a.remove();
    },
  },
};
</script>

<style lang="scss" scoped>
.comment-image {
  cursor: pointer;
}
</style>
