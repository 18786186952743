const formRules = {
  required: (message) => (v) => !!v || message || "El campo es requerido",
  numeric: (message) => (v) =>
    !isNaN(Number(v)) || message || "Cantidad no válida",
  integer: (message) => (v) =>
    !!Number.isInteger(Number(v)) || message || "Cantidad no válida",
  minValue: (minValue, message) => (v) =>
    v >= minValue || message || `Ingresa una cantidad mayor a ${minValue}`,
  maxValue: (maxValue, message) => (v) =>
    v <= maxValue || message || `Ingresa una cantidad menor a ${maxValue}`,
  email: (message) => (v) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(v) || message || "Ingresa un correo electrónico";
  },
  equals: (value, message) => (v) => {
    return v == value || message || "Los valores no coinciden";
  },
};

export default formRules;
