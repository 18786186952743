<template>
  <v-row no-gutters justify="end">
    <v-col cols="auto">
      <v-btn
        text
        color="secondary"
        small
        class="px-2"
        @click="showRecoverDialog = true"
        >Olvidé mi contraseña</v-btn
      >
    </v-col>

    <accountRecoverDialog
      v-if="showRecoverDialog"
      :show="showRecoverDialog"
      @close="showRecoverDialog = false"
    />
  </v-row>
</template>

<script>
export default {
  name: "forgottenPassword",

  components: {
    accountRecoverDialog: () => import("./accountRecover.dialog.vue"),
  },

  data() {
    return {
      showRecoverDialog: false,
    };
  },
};
</script>
