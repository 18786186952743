var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-2"},[_c('v-card',{attrs:{"tile":"","flat":""}},[_c('v-toolbar',{attrs:{"color":"secondary","dark":"","flat":"","dense":""}},[_c('v-toolbar-title',[_vm._v("Acciones")])],1),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[(
            _vm.user.role.role === 0 ||
            _vm.user.permissions.filter(function (e) { return e.key === 330; }).length > 0
          )?_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"secondary","small":"","text":""},on:{"click":function($event){return _vm.$emit('editRepairInfo')}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-pencil")]),_vm._v("Editar información")],1)],1):_vm._e(),(
            _vm.user.role.role === 0 ||
            _vm.user.permissions.filter(function (e) { return e.key === 330; }).length > 0
          )?_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"secondary","small":"","text":""},on:{"click":function($event){return _vm.$emit('showTechnician')}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-account-arrow-right")]),_c('span',[_vm._v("Asignar a técnico")])],1)],1):_vm._e(),(
            _vm.user.role.role === 0 ||
            _vm.user.permissions.filter(function (e) { return e.key === 331; }).length > 0
          )?_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"secondary","small":"","text":""},on:{"click":function($event){return _vm.$emit('editRepairStatus')}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-devices")]),_vm._v("Cambiar estado")],1)],1):_vm._e(),(
            _vm.user.role.role === 0 ||
            _vm.user.permissions.filter(function (e) { return e.key === 330; }).length > 0
          )?_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"secondary","small":"","text":""},on:{"click":function($event){return _vm.$emit('openMovementDialog')}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-swap-vertical")]),_c('span',[_vm._v("Agregar movimiento")])],1)],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"secondary","small":"","text":""},on:{"click":function($event){return _vm.$emit('addComment')}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-message-reply-text")]),_vm._v("Nuevo comentario")],1)],1),(
            _vm.user.role.role === 0 ||
            _vm.user.permissions.filter(function (e) { return e.key === 322; }).length > 0
          )?_c('v-col',{attrs:{"cols":"12"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"secondary","small":"","text":""},on:{"click":function($event){return _vm.$emit('printRepair')}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-printer")]),_vm._v("Imprimir nota")],1)]}}],null,false,134018857)},[_c('span',[_vm._v("No compatible con Mozilla Firefox")])])],1):_vm._e(),(
            _vm.user.role.role === 0 ||
            _vm.user.permissions.filter(function (e) { return e.key === 322; }).length > 0
          )?_c('v-col',{attrs:{"cols":"12"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"secondary","small":"","text":""},on:{"click":function($event){return _vm.$emit('printRepairTicket')}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-ticket-confirmation")]),_vm._v("Imprimir Ticket")],1)]}}],null,false,4213566013)},[_c('span',[_vm._v("No compatible con Mozilla Firefox")])])],1):_vm._e(),(
            _vm.user.role.role === 0 ||
            _vm.user.permissions.filter(function (e) { return e.key === 322; }).length > 0
          )?_c('v-col',{attrs:{"cols":"12"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"secondary","small":"","text":""},on:{"click":function($event){return _vm.$emit('printRepairSticker')}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-sticker")]),_vm._v("Imprimir Sticker")],1)]}}],null,false,2510154826)},[_c('span',[_vm._v("En pruebas (posibles fallos)")])])],1):_vm._e(),(
            _vm.user.role.role === 0 ||
            _vm.user.permissions.filter(function (e) { return e.key === 322; }).length > 0
          )?_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"secondary","small":"","text":""},on:{"click":function($event){return _vm.$emit('downloadRepairPdf')}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-download")]),_vm._v("Descargar nota")],1)],1):_vm._e(),([0, 1, 2].indexOf(_vm.user.role.role) !== -1)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"#075e54","small":"","text":""},on:{"click":function($event){return _vm.$emit('sendWhatsApp')}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-whatsapp")]),_vm._v(" Enviar Whatsapp ")],1)],1):_vm._e(),(
            _vm.user.role.role === 0 ||
            _vm.user.permissions.filter(function (e) { return e.key === 340; }).length > 0
          )?_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"error darken-2","small":"","text":""},on:{"click":function($event){return _vm.$emit('deleteRepair')}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-delete")]),_vm._v("Eliminar reparación")],1)],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }