var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":"","tile":""}},[_c('v-card-title',[_c('span',[_vm._v("Filtros")])]),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"outlined":"","color":"secondary","label":"Buscar número de venta"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.filtersChanged()}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-btn',{attrs:{"small":"","color":"secondary"},on:{"click":function($event){return _vm.filtersChanged()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-magnify")])],1)]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"readonly":"","filled":"","dense":"","label":"Desde","color":"secondary"},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.since)?_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.since = null;
                    _vm.filtersChanged();}}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e()]},proxy:true}],null,true),model:{value:(_vm.since),callback:function ($$v) {_vm.since=$$v},expression:"since"}},'v-text-field',attrs,false),on))]}}])},[_c('v-card',[_c('v-date-picker',{attrs:{"color":"secondary","max":_vm.until},on:{"change":function($event){return _vm.filtersChanged()}},model:{value:(_vm.since),callback:function ($$v) {_vm.since=$$v},expression:"since"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"readonly":"","filled":"","dense":"","label":"Hasta","color":"secondary"},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.until)?_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.until = null;
                    _vm.filtersChanged();}}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e()]},proxy:true}],null,true),model:{value:(_vm.until),callback:function ($$v) {_vm.until=$$v},expression:"until"}},'v-text-field',attrs,false),on))]}}])},[_c('v-card',[_c('v-date-picker',{attrs:{"color":"secondary","min":_vm.since},on:{"change":function($event){return _vm.filtersChanged()}},model:{value:(_vm.until),callback:function ($$v) {_vm.until=$$v},expression:"until"}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }