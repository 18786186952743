<template>
  <div>
    <v-alert v-if="!hasPermission(722)" type="error">
      <span
        >Al parecer no cuentas con el permiso necesario para generar ventas,
        solicita que se te asigne con algún administrador.</span
      >
    </v-alert>
    <v-row>
      <v-col cols="12" md="6" lg="4">
        <product-list
          :isInSale="true"
          @productSelected="productSelected"
        ></product-list>
      </v-col>
      <v-col cols="12" md="6" lg="8">
        <v-card :loading="loading" tile>
          <v-card-title>
            <span class="secondary--text">Detalles</span>
          </v-card-title>
          <v-card-subtitle>
            <v-card color="primary" dark class="my-2">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>Total de venta</v-list-item-subtitle>
                  <v-list-item-title>{{
                    currencyFormat(saleTotal)
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card>
            <v-btn
              color="secondary"
              block
              :loading="loading"
              :disabled="!items.length"
              @click="submit()"
            >
              <v-icon small class="mr-2">mdi-content-save</v-icon>
              <span>Guardar venta</span>
            </v-btn>
          </v-card-subtitle>
          <v-card-text>
            <v-alert v-if="error" type="error">{{ error }}</v-alert>
            <v-row dense>
              <v-col cols="12" lg="6">
                <v-select
                  v-model="branchOffice"
                  :items="branchOffices"
                  item-text="name"
                  item-value="_id"
                  filled
                  dense
                  label="Sucursal"
                  hint="La sucursal seleccionada será la que registre esta venta"
                  persistent-hint
                  color="secondary"
                  item-color="secondary"
                  :readonly="!hasPermission(725)"
                ></v-select>
              </v-col>
              <v-col cols="12" lg="6">
                <v-select
                  v-model="paymentMethod"
                  :items="paymentMethods"
                  outlined
                  color="secondary"
                  item-color="secondary"
                  label="Método de pago"
                ></v-select>
              </v-col>
            </v-row>
            <v-row v-if="items.length">
              <v-col cols="12">
                <v-subheader class="ma-0 pa-0"
                  >Productos seleccionados</v-subheader
                >
              </v-col>
              <v-col
                v-for="item in items"
                :key="item.product._id"
                cols="12"
                lg="6"
              >
                <v-card outlined tile>
                  <v-card-title>
                    <span>{{ item.product.name }}</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="removeItem(item)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text>
                    <v-row dense>
                      <v-col v-if="!hasEnoughExistences(item)" cols="12">
                        <v-alert type="error" dense text>
                          <span
                            >No hay suficientes existencias en esta
                            sucursal</span
                          >
                        </v-alert>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="item.amount"
                          type="number"
                          outlined
                          dense
                          label="Piezas"
                          color="secondary"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-chip label
                          >Subtotal:
                          {{ currencyFormat(getSubtotal(item)) }}</v-chip
                        >
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <div v-else class="pt-3">
              <span
                >Sin artículos agregados, selecciona alguno en la lista de
                productos para poder registrar la venta.</span
              >
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar v-model="showSuccessMessage" color="success">
      <span>Venta agregada con éxito</span>
    </v-snackbar>

    <print-confirmation
      v-if="showPrintConfirmation"
      :show="showPrintConfirmation"
      :sale="sale"
      @cancel="closePrintConfirmation()"
    ></print-confirmation>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import serverRequestMixin from "@/mixins/serverRequest.mixin";
import { currencyFormat } from "@/helpers/numbers.helper";

import productList from "../products/productList.vue";

export default {
  mixins: [serverRequestMixin],

  components: {
    "product-list": productList,
    "print-confirmation": () => import("./saleTicket.dialog.vue"),
  },

  computed: {
    ...mapGetters(["user", "hasPermission"]),

    saleTotal() {
      let result = 0;

      this.items.forEach((item) => {
        const subtotal = item.product.unitCost * item.amount;
        result = result + subtotal;
      });

      return result;
    },
  },

  data: () => ({
    loading: false,
    error: "",
    items: [],
    branchOffice: "",
    branchOffices: [],
    paymentMethods: ["Efectivo", "Tarjeta"],
    paymentMethod: "Efectivo",
    showSuccessMessage: false,
    sale: null,
    showPrintConfirmation: false,
  }),

  mounted() {
    this.getBranchOffices();
  },

  methods: {
    currencyFormat,

    async getBranchOffices() {
      this.loading = true;

      try {
        const serverResponse = await this.getRequest("/branchOffices");
        this.loading = false;

        this.branchOffices = serverResponse.branchOffices;
        if (this.user.branchOffice)
          this.branchOffice = this.user.branchOffice._id;
      } catch (error) {
        this.handleResponseError(error);
      }
    },

    productSelected(product) {
      const productIndex = this.items.findIndex(
        (e) => e.product._id === product._id
      );

      const productExist = productIndex >= 0;

      if (productExist)
        this.items[productIndex].amount = this.items[productIndex].amount + 1;
      else
        this.items.push({
          product,
          amount: 1,
          subtotal: product.unitCost,
        });
    },

    hasEnoughExistences(item) {
      const branchOfficeExistences = item.product.stock.find(
        (e) => e.branchOffice._id == this.branchOffice
      );

      if (!branchOfficeExistences) return false;
      if (branchOfficeExistences.existences < item.amount) return false;
      return true;
    },

    getSubtotal(item) {
      return item.product.unitCost * item.amount;
    },

    removeItem(item) {
      this.items = this.items.filter((e) => e.product._id !== item.product._id);
    },

    async submit() {
      this.loading = true;
      this.error = "";

      try {
        const items = this.items.map((e) => {
          return { product: e.product._id, amount: e.amount };
        });
        const body = {
          branchOffice: this.branchOffice,
          items,
          paymentMethod: this.paymentMethod,
        };

        const serverResponse = await this.postRequest("/sales", body);
        this.loading = false;

        this.showSuccessMessage = true;
        this.openPrintConfirmation(serverResponse.sale);
        this.clearSaleData();
      } catch (error) {
        this.handleResponseError(error);
      }
    },

    openPrintConfirmation(sale) {
      this.sale = sale;
      this.showPrintConfirmation = true;
    },

    closePrintConfirmation() {
      this.showPrintConfirmation = false;
      this.sale = null;
    },

    clearSaleData() {
      this.items = [];
    },
  },
};
</script>
