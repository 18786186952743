<template>
  <v-card outlined tile>
    <v-card-title>
      <span>Filtros</span>
    </v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="search"
            outlined
            color="secondary"
            label="Buscar número de venta"
            @keyup.enter="filtersChanged()"
          >
            <template #append>
              <v-btn small color="secondary" @click="filtersChanged()">
                <v-icon small>mdi-magnify</v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-menu>
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="since"
                v-on="on"
                v-bind="attrs"
                readonly
                filled
                dense
                label="Desde"
                color="secondary"
              >
                <template #append>
                  <v-btn
                    v-if="since"
                    icon
                    small
                    @click="
                      since = null;
                      filtersChanged();
                    "
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </template>
            <v-card>
              <v-date-picker
                v-model="since"
                color="secondary"
                :max="until"
                @change="filtersChanged()"
              ></v-date-picker>
            </v-card>
          </v-menu>
        </v-col>
        <v-col cols="12" md="3">
          <v-menu>
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="until"
                v-on="on"
                v-bind="attrs"
                readonly
                filled
                dense
                label="Hasta"
                color="secondary"
              >
                <template #append>
                  <v-btn
                    v-if="until"
                    icon
                    small
                    @click="
                      until = null;
                      filtersChanged();
                    "
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </template>
            <v-card>
              <v-date-picker
                v-model="until"
                color="secondary"
                :min="since"
                @change="filtersChanged()"
              ></v-date-picker>
            </v-card>
          </v-menu>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    search: "",
    since: null,
    until: null,
  }),

  mounted() {
    this.setCurrent();
  },

  methods: {
    filtersChanged() {
      const filters = {
        search: this.search,
        since: this.since,
        until: this.until,
      };
      this.$emit("filtersChanged", filters);
    },

    setCurrent() {
      const { query } = this.$route;
      if (query.search) this.search = query.search;
      if (query.since) this.since = query.since;
      if (query.until) this.until = query.until;
    },
  },
};
</script>
