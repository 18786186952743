<template>
  <v-dialog
    v-model="show"
    persistent
    scrollable
    :fullscreen="isMobile"
    width="700"
    hide-overlay
  >
    <v-card>
      <v-toolbar color="secondary" dark tile dense flat>
        <v-toolbar-title>
          <span>Detalle de venta</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('cancel')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <div>
          <v-subheader class="ma-0 pa-0">Datos generales</v-subheader>
        </div>
        <v-row dense>
          <v-col cols="12" md="6">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>Número de venta</v-list-item-subtitle>
                <v-list-item-title>{{ sale.saleNumber }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" md="6">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>Fecha de venta</v-list-item-subtitle>
                <v-list-item-title>{{
                  getFullDateWithHour(sale.date)
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" md="6">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle
                  >Usuario que registró la venta</v-list-item-subtitle
                >
                <v-list-item-title>{{ sale.user.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" md="6">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>Sucursal de venta</v-list-item-subtitle>
                <v-list-item-title>{{
                  sale.branchOffice.name
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" md="6">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>Método de pago</v-list-item-subtitle>
                <v-list-item-title>
                  <span>{{ sale.payment.paymentMethod }}</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" md="6">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>Monto total</v-list-item-subtitle>
                <v-list-item-title>
                  <v-chip small>
                    <span>{{ currencyFormat(sale.total) }}</span>
                  </v-chip>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
        <div>
          <v-subheader class="ma-0 pa-0">Productos agregados</v-subheader>
        </div>
        <v-list>
          <template v-for="productItem in sale.items">
            <div :key="productItem._id">
              <v-list-item three-line>
                <v-list-item-icon>
                  <v-avatar color="grey">
                    <v-icon dark>mdi-package</v-icon>
                  </v-avatar>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <span>{{ productItem.product.name }}</span>
                    <v-chip label small class="ml-2">
                      <span>{{ productItem.product.sku }}</span>
                    </v-chip>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <span
                      >Costo unitario:
                      {{ currencyFormat(productItem.productUnitCost) }}</span
                    >
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    <span
                      >X{{ productItem.amount }}:
                      {{ currencyFormat(productItem.subtotal) }}</span
                    >
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </div>
          </template>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { currencyFormat } from "@/helpers/numbers.helper";
import { getFullDateWithHour } from "@/helpers/date.helper";

export default {
  props: {
    show: { type: Boolean, default: false },
    sale: { type: Object, required: true },
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },

  methods: {
    currencyFormat,
    getFullDateWithHour,
  },
};
</script>
