<template>
  <div>
    <sales-list></sales-list>
  </div>
</template>

<script>
import salesList from "./salesList.vue";

export default {
  components: {
    "sales-list": salesList,
  },
};
</script>
