<template>
  <v-dialog v-model="show" persistent width="600">
    <v-card :loading="loading">
      <v-card-title>Agregar comentario</v-card-title>
      <v-card-text>
        <v-alert type="error" v-if="submitError" outlined>{{
          submitError
        }}</v-alert>
        <v-textarea
          filled
          v-model="comment"
          rows="4"
          label="Comentario"
          color="secondary"
          :error-messages="errors.comment"
          @input="validateComment"
          @blur="validateComment"
        ></v-textarea>
        <v-form v-model="isImageValid" ref="imageForm" lazy-validation>
          <v-file-input
            v-model="imageFile"
            :label="
              loading ? 'Subiendo archivos...' : 'Adjuntar archivos (opcional)'
            "
            multiple
            filled
            color="secondary"
            prepend-inner-icon="mdi-file"
            :disabled="loading"
            :prepend-icon="null"
            :accept="accept.join(',')"
            :rules="imageRules"
          ></v-file-input>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="secondary"
          outlined
          :disabled="loading"
          @click="$emit('cancel')"
        >
          <v-icon small class="mr-2">mdi-close</v-icon>
          <span>Cancelar</span>
        </v-btn>
        <v-btn
          color="primary"
          :loading="loading"
          :disabled="loading || !isFormValid"
          @click="submit"
        >
          <v-icon small class="mr-2" color="secondary">mdi-content-save</v-icon>
          <span class="secondary--text">Guardar</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import serverRequestMixin from "@/mixins/serverRequest.mixin";

const acceptedFileTypes = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "application/pdf",
];

export default {
  name: "commentDialog",

  mixins: [validationMixin, serverRequestMixin],

  props: {
    show: { type: Boolean, default: false },
    invoiceId: { type: [String, Number], required: true },
  },

  computed: {
    isFormValid() {
      if (this.errors.comment.length) return false;
      return true;
    },
  },

  data: () => ({
    loading: false,
    comment: "",
    submitError: "",
    errors: {
      comment: [],
    },
    isImageValid: false,
    imageFile: [],
    imageRules: [
      // (image) => {
      //   console.log({ image });
      //   return true;
      // },
      (image) =>
        !image ||
        image.every((e) => e.size < 1 * 1024 * 1024) ||
        "El archivo de imagen no puede exceder 1Mb",
      (image) =>
        !image ||
        image.every((imageFile) =>
          acceptedFileTypes.some((e) => e == imageFile.type)
        ) ||
        "Tipo de archivo inválido, tipos aceptados: jpeg, jpg, png",
    ],
    accept: [".png", ".jpg", ".jpeg", ".pdf"],
  }),

  validations: {
    comment: { required },
  },

  methods: {
    validateComment() {
      let errors = [];
      this.$v.comment.$touch();
      !this.$v.comment.required && errors.push("El comentario es requerido");
      this.errors.comment = errors;
    },

    async submit() {
      this.submitError = "";
      this.validateComment();
      if (!this.isFormValid || !this.$refs.imageForm.validate()) return;

      this.loading = true;

      try {
        let serverResponse = await this.postRequest("/comments", {
          invoiceId: this.invoiceId,
          content: this.comment,
        });

        if (this.imageFile.length)
          for (let file of this.imageFile) {
            await this.uploadFile(file, serverResponse.comment._id);
          }

        this.loading = false;

        this.$emit("commentSaved", serverResponse.comment);
      } catch (error) {
        console.error(error);
        this.loading = false;
        this.submitError = error.data.message;
        if (error.status >= 500) console.error(error);
      }
    },

    async uploadFile(file, commentId) {
      const fileToUpload = new FormData();
      fileToUpload.append("file", file);

      await this.postRequest(`/comments/${commentId}/files`, fileToUpload);
    },
  },
};
</script>
