<template>
  <div>
    <v-card :loading="loading" tile flat>
      <v-toolbar color="secondary" dark tile dense flat>
        <v-toolbar-title>
          <span>Listado de ventas ({{ count }} resultados)</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn v-if="hasPermission(722)" color="primary" @click="goNewSale()">
          <v-icon small>mdi-cart-plus</v-icon>
          <span v-if="!isMobile" class="ml-2">Generar venta</span>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-alert v-if="error" type="error">{{ error }}</v-alert>
        <v-row dense>
          <v-col cols="12">
            <sale-pagination
              :lastPage="lastPage"
              @changePage="changePage"
            ></sale-pagination>
          </v-col>
          <v-col cols="12">
            <sales-filters @filtersChanged="filtersChanged"></sales-filters>
          </v-col>
        </v-row>
        <v-list v-if="sales.length">
          <template v-for="sale in sales">
            <div :key="sale._id">
              <v-list-item
                :two-line="!hasPermission(725)"
                :three-line="!!hasPermission(725)"
                @click="openSaleDetails(sale)"
              >
                <v-list-item-icon>
                  <v-icon color="green darken-2">mdi-cart</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <v-chip color="accent" small label class="mr-2">
                      <span class="secondary--text">{{ sale.saleNumber }}</span>
                    </v-chip>
                    <span>{{ getFullDateWithHour(sale.date) }}</span>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <span
                      >Monto de venta:
                      <v-chip small>{{
                        currencyFormat(sale.total)
                      }}</v-chip></span
                    >
                  </v-list-item-subtitle>
                  <v-list-item-subtitle v-if="hasPermission(725)">
                    <span><strong>Sucursal: </strong></span>
                    <span>{{ sale.branchOffice.name }}</span>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-menu v-if="hasPermission(722)" left>
                    <template #activator="{ on, attrs }">
                      <v-btn v-on="on" v-bind="attrs" icon>
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="openSalePrintConfirmation(sale)">
                        <v-list-item-icon class="mr-2">
                          <v-icon small>mdi-ticket-confirmation</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>
                            <span>Imprimir comprobante</span>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-list-item-action>
              </v-list-item>
              <v-divider></v-divider>
            </div>
          </template>
        </v-list>
        <div v-else class="py-2">
          <span>Sin ventas para mostrar.</span>
        </div>
      </v-card-text>
    </v-card>

    <sale-details
      v-if="showDetails"
      :show="showDetails"
      :sale="selectedSale"
      @cancel="closeSaleDetails()"
    ></sale-details>

    <sale-print
      v-if="showPrintConfirmation"
      :show="showPrintConfirmation"
      :sale="selectedSale"
      :isFirstTime="false"
      @cancel="closeSalePrintConfirmation()"
    ></sale-print>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import serverRequestMixin from "@/mixins/serverRequest.mixin";

import { currencyFormat } from "@/helpers/numbers.helper";
import { getFullDateWithHour } from "@/helpers/date.helper";

import saleDetails from "./saleDetails.vue";
import pagination from "@/components/layout/pagination.vue";
import salesFilters from "./salesFilters.vue";

export default {
  mixins: [serverRequestMixin],

  components: {
    "sale-details": saleDetails,
    "sale-print": () => import("./saleTicket.dialog.vue"),
    "sale-pagination": pagination,
    "sales-filters": salesFilters,
  },

  data: () => ({
    loading: false,
    error: "",
    sales: [],
    showDetails: false,
    showPrintConfirmation: false,
    selectedSale: null,
    count: 0,
  }),

  computed: {
    ...mapGetters(["hasPermission"]),

    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },

    lastPage() {
      const itemsPerPage = 20;
      return parseInt(this.count / itemsPerPage + 0.9999999);
    },
  },

  watch: {
    $route() {
      this.getSales();
    },
  },

  mounted() {
    this.getSales();
  },

  methods: {
    getFullDateWithHour,
    currencyFormat,

    async getSales() {
      this.loading = true;

      try {
        const { query } = this.$route;
        const serverResponse = await this.getRequest("/sales", true, query);
        this.loading = false;

        this.sales = serverResponse.sales;
        this.count = serverResponse.salesCount;
      } catch (error) {
        this.loading = false;
        if (error.data) this.error = error.data.message;
        else {
          this.error = "Error inesperado, favor de contactar con soporte";
          console.error(error);
        }
      }
    },

    openSaleDetails(sale) {
      this.selectedSale = sale;
      this.showDetails = true;
    },

    closeSaleDetails() {
      this.showDetails = false;
      this.selectedSale = null;
    },

    openSalePrintConfirmation(sale) {
      this.selectedSale = sale;
      this.showPrintConfirmation = true;
    },

    closeSalePrintConfirmation() {
      this.showPrintConfirmation = false;
      this.selectedSale = null;
    },

    goNewSale() {
      this.$router.push({
        name: "Nueva venta",
      });
    },

    changePage(page) {
      const query = {
        ...this.$route.query,
        page,
      };

      this.$router.push({
        name: this.$route.name,
        query,
      });
    },

    filtersChanged(filters) {
      const query = {
        ...this.$route.query,
        ...filters,
      };

      this.$router.push({
        name: this.$route.name,
        query,
      });
    },
  },
};
</script>
