<template>
  <div class="d-flex">
    <v-btn
      small
      tile
      color="secondary"
      :disabled="!canGoFirstPage"
      @click="goBack()"
    >
      <v-icon>mdi-chevron-left</v-icon>
    </v-btn>
    <v-btn
      v-if="canGoFirstPage"
      color="secondary"
      outlined
      small
      tile
      class="ml-2"
      @click="goToPage(1)"
    >
      <span>1</span>
    </v-btn>
    <v-btn color="primary" tile small class="ml-2">
      <span>{{ currentPage }}</span>
    </v-btn>
    <v-btn
      v-if="canGoLastPage"
      color="secondary"
      outlined
      small
      tile
      class="ml-2"
      @click="goToPage(lastPage)"
    >
      <span>{{ lastPage }}</span>
    </v-btn>
    <v-btn
      small
      tile
      class="ml-2"
      color="secondary"
      :disabled="!canGoLastPage"
      @click="goNext()"
    >
      <v-icon>mdi-chevron-right</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    lastPage: { type: [Number, String], default: 1 },
  },

  computed: {
    currentPage() {
      const { page } = this.$route.query;
      if (page) return page;
      return 1;
    },

    canGoFirstPage() {
      return this.currentPage > 1;
    },

    canGoLastPage() {
      return this.lastPage > this.currentPage;
    },
  },

  methods: {
    goToPage(pageNumber) {
      this.$emit("changePage", pageNumber);
    },

    goNext() {
      this.goToPage(this.currentPage + 1);
    },

    goBack() {
      this.goToPage(this.currentPage - 1);
    },
  },
};
</script>
